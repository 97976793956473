import React from 'react'
import {Layout} from 'layouts'
import {Trans, useTranslation} from 'react-i18next'
import {graphql} from 'gatsby'
// import Img  from "gatsby-image"
// import { StaticImage } from "gatsby-plugin-image"

function Page() {
  const {t} = useTranslation()
  return (
    <Layout
      title={t('About Us')}
      description={t('We’re a Technology Company. We’re EVET')}
      className="bg-[url(assets/images/bg-our-story.svg)] bg-contain bg-top bg-no-repeat"
    >
      <section className="flex flex-col items-center text-center text-gray-900">
        <h1 className="font-head font-extrabold md:text-5xl text-2xl my-10">
          {t('OUR STORY')}
        </h1>
        <p className="md:text-3xl text-lg md:my-10 px-5">
          <Trans>
            We’re a Technology Company. We’re{' '}
            <span className="text-primary">EVET</span>
          </Trans>
        </p>
        <p className="md:text-lg text-sm px-5 text-gray-500 max-w-5xl my-10">
          {t(
            'Evet strives to create an environment that fosters exceptional and diverse creators to work together in building exceptional products, while experiencing an unforgettable journey.'
          )}
        </p>
        <p className="md:text-3xl text-lg px-5 text-gray-800 max-w-7xl my-10">
          {t(
            'We work on a lot of ideas, while creating an exciting environment and opportunities for exceptionally intelligent and hardworking people. We work and collaborate together to produce the best results, and take pride in our dedication.'
          )}
        </p>

        <div
          className="rounded-3xl
         md:my-12 my-2 w-11/12 h-56
         bg-gray-900 bg-[url(assets/images/our-story.jpg)]
         bg-cover bg-center
         md:hidden block"
        >
          &nbsp;
        </div>

        <div
          className="p-36
         flex flex-col items-center justify-center md:rounded-3xl
         xl:mx:0 md:mx-4
         my-12
         bg-gray-900 bg-[url(assets/images/our-story.jpg)]
         bg-cover bg-center
         md:block hidden"
        >
          {/*<Img className="top-0 z-0 absolute w-full h-72 " fixed={data.file.childImageSharp.fluid}*/}
          {/*     objectFit="cover"*/}
          {/*     objectPosition="50% 50%"*/}
          {/*     alt=""/>*/}
          <p
            className="text-3xl text-white max-w-7xl mb-10
          "
          >
            <Trans>
              {t(
                'Creating an environment and opportunities for exceptionally smart and hardworking\n' +
                  'people to work and collaborate together to produce amazing results.'
              )}
            </Trans>
          </p>
          <p className="text-lg text-white max-w-5xl">
            {t(
              'Our priority is being part of the journey from idea to product success. Products come and go and technologies rapidly evolve in today’s internet economy. We find great value in challenging ideas, continuous development and the satisfaction of a successful global product launch journey.'
            )}
          </p>
        </div>

        <p className="md:text-3xl text-lg px-5 text-gray-800 my-10 max-w-7xl">
          {t(
            'The name “EVET” is the Turkish word for “YES”. As the company’s name, it symbolizes embracing new and challenging ideas, while maintaining a "let’s figure this out" attitude.'
          )}
        </p>
        <p className="md:text-lg text-sm px-4 md:leading-none leading-relaxed text-gray-500 md:my-10 max-w-7xl">
          <Trans>
            {t(
              'EVET was founded in 2019 by its U.S. based founder and CEO, whose mission is to put together a group of energetic and extraordinary people with big ideas, while maintaining the drive to produce the best results possible. The company also aims to increase the reach of start-up companies by paving a path for them to get their products onto the global market.'
            )}
          </Trans>
        </p>
        <p
          className="font-semibold tracking-wide uppercase my-10
        md:text-base text-xs px-6"
        >
          {t(
            'The company operates in both the MIAMI area and Istanbul, Turkey'
          )}
        </p>
        <p className="md:text-lg text-sm px-4 md:leading-none leading-relaxed text-gray-500 mb-20 max-w-7xl">
          <Trans>
            {t(
              'One office is located in Miami, where the marketing and biz-dev members reside. The engineering and product development team, which is made of some of the best engineering minds, is located in Istanbul, Turkey.'
            )}
          </Trans>
        </p>
      </section>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    file(relativePath: {eq: "our-story.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
